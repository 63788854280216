// 中文
export default {
  hotGoods: '热销产品',
  pushGoods: '推荐产品',
  home: '首页',
  goodsList: '商品列表',
  detail: '商品详情',
  about: '关于我们',
  category: '产品类别',
  advertisement: '广告',
  mask: '参数',
  contact: '联系我们',
  phone: '客户电话',
  email: '邮箱',
  back: '返回',
  first: '首页',
  last: '尾页',
  all: '全部'
}