import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/goods',
    name: 'goods',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AboutView.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/goodsDetail.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/aboutUs.vue'),
    meta: {
      keepAlive: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 在路由导航守卫中临时隐藏滚动条
router.beforeEach((to, from, next) => {
  document.body.style.overflow = 'hidden'; // 隐藏滚动条
  next();
});

router.afterEach(() => {
  document.body.style.overflow = ''; // 恢复滚动条
});
export default router