export default {
  hotGoods: 'hot-sale product',
  pushGoods: 'Recommended products',
  home: 'home page',
  goodsList: 'Product list',
  detail: 'Product Details',
  about: 'About Us',
  category: 'Product Category',
  advertisement: 'advertisement',
  mask: 'Description',
  contact: 'Contact us',
  phone: 'phone',
  email: 'Email',
  back: 'back',
  first: 'home page',
  last: 'Last page',
   all: 'whole'
}