import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
Vue.prototype.$axios = axios;
// import VuePdfEmbed from 'vue-pdf-embed';
// Vue.use(VuePdfEmbed);

import 'amfe-flexible'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css' // 引入Element UI样式
Vue.use(ElementUI);
import './assets/media.css' // 引入全局CSS文件

import cn from './language/zh'
import en from './language/english'
// 国际化
import VueI18n from 'vue-i18n'
Vue.use(VueI18n) // 通过插件的形式挂载
const messages = {
  cn,
  en
}
const i18n = new VueI18n({
  messages,
  locale: localStorage.getItem('lang') || 'cn', // 语言标识
})

Vue.prototype.$urlImg = function (url) {
  return 'https://gl.wdmetalmaterial.com/prod-api' + url
}
Vue.config.productionTip = false
Vue.prototype.$context_path = "/prod-api";

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')