import request from "./request";
// 热销产品列表
export function productList(params) {
  return request({
    url: '/frontMessage/productList',
    method: "get",
    params
  });
}

// 获取产品类别
export function categoryinfo(params) {
  return request({
    url: '/frontMessage/categoryinfo',
    method: "get",
    params
  });
}

// 根据产品类别  获取产品信息
export function frontproductList(params) {
  return request({
    url: '/frontMessage/productList',
    method: "get",
    params
  });
}

// 根据产品类别  获取产品信息
export function productInfo(params) {
  return request({
    url: '/frontMessage/productInfo',
    method: "get",
    params
  });
}

//  根据产品编号  获取产品参数信息
export function productInfolist(params) {
  return request({
    url: '/frontMessage/list',
    method: "get",
    params
  });
}

// 获取轮播图列表
export function balancelist(params) {
  return request({
    url: '/frontMessage/balancelist',
    method: "get",
    params
  });
}

// 获取广告列表
export function advertisementlist(params) {
  return request({
    url: '/frontMessage/advertisementlist',
    method: "get",
    params
  });
}

// 关于我们
export function aboutus(params) {
  return request({
    url: '/frontMessage/aboutus',
    method: "get",
    params
  });
}

// 邮箱电话
export function callmessage(params) {
  return request({
    url: '/frontMessage/callmessage',
    method: "get",
    params
  });
}