<template>
  <div class="home">
    <div class="banners" v-if="bannerList[0]">
      <el-carousel class="banner">
        <el-carousel-item v-for="item in bannerList" :key="item.bh">
          <img
            @click="goUrl(item)"
            ref="bannerHeight"
            :src="$urlImg(item.path)"
            style="width: 100%; height: auto"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="goods">
      <div class="goods-tlt">{{ $t('hotGoods') }}</div>
      <div class="goods-list">
        <div
          class="goods-item"
          v-for="i in hotList"
          :key="i.id"
          @click="goGoods(i)"
        >
          <div class="goods-pic">
            <img :src="$urlImg(i.image)" alt="" />
          </div>
          <div class="goods-mask">
            {{ i.caname }}
          </div>
          <div class="goods-btn">{{ i.jg }}{{ i.dw }}</div>
        </div>
      </div>
    </div>

    <!-- <div class="goods">
      <div class="goods-list">
        <div
          class="goods-item"
          v-for="i in hotList"
          :key="i.id"
          @click="goGoods(i)"
        >
          <div class="goods-pic">
            <img :src="$urlImg(i.image)" alt="" />
          </div>
          <div class="goods-mask">
            {{ i.caname }}
          </div>
          <div class="goods-btn">{{ i.jg }}{{ i.dw }}</div>
        </div>
      </div>
    </div> -->
    <div class="batdau">
      <div class="bat-btn">{{ $t('category') }}</div>
      <div class="content1">
        <div
          class="cont-item"
          v-for="i in category"
          :key="i.id"
          @click="goCate(i)"
        >
          <img class="item-pic" :src="$urlImg(i.image)" alt="" />
          <div class="item-tlt">{{ i.label }}</div>
          <!-- <div class="item-btn">Xem thêm</div> -->
        </div>
      </div>
    </div>
    <div class="phats">
      <div class="phats-btn">{{ $t('pushGoods') }}</div>
      <div class="list-top">
        <div
          class="top-item"
          v-for="i in pushList.slice(0, 3)"
          :key="i.id"
          @click="goGoods(i)"
        >
          <img class="top-pic" :src="$urlImg(i.image)" alt="" />
          <div class="top-mask">
            <div class="mask2">{{ i.caname }}</div>
            <div class="top-num">{{ i.jg }}{{ i.dw }}</div>
          </div>
        </div>
      </div>
      <div class="list-bot">
        <div
          class="top-item"
          @click="goGoods(i)"
          v-for="i in pushList.slice(3, 5)"
          :key="i"
        >
          <img class="top-pic" :src="$urlImg(i.image)" alt="" />
          <div class="top-mask">
            <div class="mask2">{{ i.caname }}</div>
            <div class="top-num">{{ i.jg }}{{ i.dw }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bot-btn">{{ $t('advertisement') }}</div>
    <div class="bot-cont">
      <div class="seller">
        <div class="swipers">
          <div class="swiper">
            <el-carousel class="swiper2" @change="change">
              <el-carousel-item v-for="(item, index) in ggList" :key="index">
                <img
                  ref="bannerHeight"
                  :src="$urlImg(item.path)"
                  style="width: 100%; height: auto"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="swiper-bot">
            <div class="sw-tlt">
              {{ ggIngo }}
            </div>
      
          </div>
        </div>
   
      </div>
    </div> -->
  </div>
</template>
<script>
import {
  productList,
  balancelist,
  advertisementlist,
  categoryinfo
} from '@/api/index'
export default {
  name: 'HomeView',
  data() {
    return {
      language: 'cn', // 选中的语言
      languagelist: ['cn', 'en'],
      bannerList: [],
      ggList: [],
      hotList: [],
      pushList: [],
      category: [],
      ggIngo: ''
    }
  },
  components: {},
  computed: {},
  mounted() {},
  created() {
    // 获取缓存的语种，如若没有（新用户），保存当前默认语言
    if (localStorage.getItem('lang')) {
      this.language = localStorage.getItem('lang')
    } else {
      localStorage.setItem('lang', this.language)
    }
    this.productList()
    this.productList2()
    this.balancelist()
    this.advertisementlist()
    this.categoryinfo()
  },
  methods: {
    goUrl(item) {
      // console.log('11111')
      if (item.url) {
        window.location.href = item.url // 替换为你的目标链接
      } else {
        return
      }
    },
    translate(lang) {
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
    },
    change(ele) {
      console.log('this.elee4441111', ele)
      this.ggList.forEach((ele2, index) => {
        // console.log('this.elee4441111', ele, index)
        if (ele == index) {
          this.ggIngo = ele2.message
        }
      })
    },
    goCate(item) {
      this.$router.push({
        path: '/goods',
        query: { cateId: item.id }
      })
    },
    goGoods(item) {
      this.$router.push({
        path: '/detail',
        query: { id: item.bh }
      })
    },

    categoryinfo() {
      categoryinfo({
        lanage: localStorage.getItem('lang')
      }).then((res) => {
        if (res.code == 200) {
          this.category = res.data
        }
      })
    },
    productList() {
      productList({
        lanage: localStorage.getItem('lang'),
        id: 0,
        hot: 0
      }).then((res) => {
        console.log(res)
        this.hotList = res.rows
      })
    },
    productList2() {
      productList({
        lanage: localStorage.getItem('lang'),
        id: 0,
        push: 0
      }).then((res) => {
        console.log(res)
        this.pushList = res.rows
      })
    },
    balancelist() {
      balancelist({
        lanage: localStorage.getItem('lang')
      }).then((res) => {
        this.bannerList = res.data
      })
    },
    advertisementlist() {
      advertisementlist({
        lanage: localStorage.getItem('lang')
      }).then((res) => {
        if (res.code == 200) {
          this.ggList = res.data
          this.ggIngo = this.ggList[0].message
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/home.css';
.home {
  background: #ffffff;
  padding-bottom: 60px;
}
.banners {
  width: 100%;
  height: 530px;
  .banner.el-carousel {
    height: 530px !important;
    .el-carousel__container {
      height: 530px !important;
      position: relative !important;
      .el-carousel__item {
        height: 530px !important;
        background-size: 100% 100%;
        background-blend-mode: multiply;
        .el-carousel__item img {
          height: 530px !important;
          mix-blend-mode: multiply !important;
        }
      }
    }
    .el-carousel__indicators {
      bottom: 50px !important;
      .el-carousel__indicator {
        .el-carousel__button {
          width: 12px !important;
          height: 12px !important;
          background: #d9d9d9 !important;
          border-radius: 50% !important;
        }
      }
      .is-active.el-carousel__indicator {
        .el-carousel__button {
          background-color: #e68a1e !important;
        }
      }
    }
  }
}
.goods {
  width: 973px;
  margin: 0 auto;
  margin-top: 48px;
  padding-top: 26px;
  .goods-tlt {
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    font-size: 20px;
    color: #12416b;
    line-height: 23px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .goods-list {
    width: 100%;
    display: flex;
    margin-top: 39px;
    // flex-wrap: wrap;
    overflow: hidden;
    overflow-x: scroll;
    .goods-item {
      width: 300px;
      height: auto;
      background: #ffffff;
      margin-right: 36px;
      margin-bottom: 20px;
      cursor: pointer;
      .goods-pic {
        width: 300px;
        height: 300px;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #e8e8e8;
        padding: 22px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .goods-mask {
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 20px;
        color: #12416b;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 27px;
      }
      .goods-btn {
        width: 114px;
        height: 28px;
        background: #e87912;
        border-radius: 19px 19px 19px 19px;
        text-align: center;
        line-height: 28px;
        font-family: Montserrat, Montserrat;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        margin-top: 20px;
      }
    }
    .goods-item:nth-child(3n) {
      // margin-right: 0;
    }
  }
  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 12px; /* 宽度 */
    height: 8px;
    background-color: #f9f9f9; /* 背景色 */
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background: #e1e1e1; /* 轨道颜色 */
    border-radius: 10px; /* 轨道圆角 */
  }

  /* 滚动条的滑块 */
  ::-webkit-scrollbar-thumb {
    height: 6px;
    background-color: #c1c1c1; /* 滑块颜色 */
    border-radius: 10px; /* 滑块圆角 */
    border: 2px solid #ffffff; /* 滑块边框 */
  }

  /* 滑块在悬停或者点击时的样式 */
  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8; /* 悬停或点击时滑块颜色 */
  }
}
.banner2 {
  width: 973px;
  height: 245px;
  margin: 0 auto;
  margin-top: 70px;
  .banners {
    width: 100%;
    height: 100%;
  }
}
.batdau {
  width: 100%;
  height: auto;
  background: #cee3f4;
  border-radius: 0px 0px 0px 0px;
  margin-top: 95px;
  padding: 0 200px;
  overflow: hidden;
  padding-bottom: 18px;
  .bat-btn {
    width: 430px;
    height: 60px;
    background: url(../assets/image/btnbg.png) no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 30px;
    font-family: Montserrat, Montserrat;
    font-weight: 800;
    font-size: 25px;
    color: #ffffff;
    line-height: 60px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .content1 {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    // overflow: hidden;
    // overflow-x: scroll;

    .cont-item {
      width: 228px;
      height: 228px;
      background: #e4e4e4;
      border-radius: 0px 0px 0px 0px;
      padding: 46px 55px 22px 55px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      .item-pic {
        width: 74px;
        height: 56px;
        display: block;
        margin: 0 auto;
      }
      .item-tlt {
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        line-height: 23px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top: 19px;
      }
      .item-btn {
        width: 95px;
        height: 20px;
        background: #ffffff;
        border-radius: 19px 19px 19px 19px;
        margin: 0 auto;
        margin-top: 19px;
        font-family: Montserrat, Montserrat;
        font-weight: 500;
        font-size: 10px;
        color: #575757;
        line-height: 20px;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }
    .cont-item:nth-child(2n) {
      background: #f5911e;
    }
    .cont-item:nth-child(3n) {
      background: #12416b;
    }
    .cont-item:nth-child(4n) {
      margin-right: 0;
      background: #aed1ec;
    }
    // .cont-item:last-child {

    // }
  }
  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 12px; /* 宽度 */
    height: 8px;
    background-color: #f9f9f9; /* 背景色 */
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background: #e1e1e1; /* 轨道颜色 */
    border-radius: 10px; /* 轨道圆角 */
  }

  /* 滚动条的滑块 */
  ::-webkit-scrollbar-thumb {
    height: 6px;
    background-color: #c1c1c1; /* 滑块颜色 */
    border-radius: 10px; /* 滑块圆角 */
    border: 2px solid #ffffff; /* 滑块边框 */
  }

  /* 滑块在悬停或者点击时的样式 */
  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8; /* 悬停或点击时滑块颜色 */
  }
}
.phats {
  width: 973px;
  margin: 0 auto;
  margin-top: 120px;
  .phats-btn {
    width: 432px;
    height: 60px;
    background: url(../assets/image/btnbg.png) no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-bottom: 46px;
    font-family: Montserrat, Montserrat;
    font-weight: 800;
    font-size: 25px;
    color: #ffffff;
    line-height: 60px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .list-top {
    width: 100%;
    height: 256px;
    display: flex;
    // justify-content: space-between;
    .top-item {
      width: 280px;
      height: 256px;
      background: #fafafa;
      border-radius: 0px 0px 0px 0px;
      border: 1px solid #e8e8e8;
      margin-right: 62px;
      cursor: pointer;
      .top-pic {
        width: 280px;
        height: 148px;
      }
      .top-mask {
        width: 280px;
        height: 108px;
        background: #ebebeb;
        border-radius: 0px 0px 0px 0px;
        padding: 11px 47px 24px 47px;
        .mask2 {
          font-family: Montserrat, Montserrat;
          font-weight: bold;
          font-size: 20px;
          color: #12416b;
          line-height: 24px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
        .top-num {
          width: 95px;
          height: 20px;
          background: #ffffff;
          border-radius: 19px 19px 19px 19px;
          margin: 0 auto;
          margin-top: 6px;
          font-family: Montserrat, Montserrat;
          font-weight: 500;
          font-size: 10px;
          color: #575757;
          line-height: 20px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }
    .top-item :nth-child(3n) {
      margin-right: 0;
    }
  }
  .list-bot {
    width: 616px;
    height: 256px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 56px;
    .top-item {
      width: 280px;
      height: 256px;
      background: #fafafa;
      border-radius: 0px 0px 0px 0px;
      border: 1px solid #e8e8e8;
      cursor: pointer;
      .top-pic {
        width: 280px;
        height: 148px;
      }
      .top-mask {
        width: 280px;
        height: 108px;
        background: #ebebeb;
        border-radius: 0px 0px 0px 0px;
        padding: 11px 47px 24px 47px;
        .mask2 {
          font-family: Montserrat, Montserrat;
          font-weight: bold;
          font-size: 20px;
          color: #12416b;
          line-height: 24px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
        .top-num {
          width: 95px;
          height: 20px;
          background: #ffffff;
          border-radius: 19px 19px 19px 19px;
          margin: 0 auto;
          margin-top: 6px;
          font-family: Montserrat, Montserrat;
          font-weight: 500;
          font-size: 10px;
          color: #575757;
          line-height: 20px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }
}
.bot-btn {
  width: 432px;
  height: 60px;
  background: url(../assets/image/btnbg.png) no-repeat center;
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 20px;
  font-family: Montserrat, Montserrat;
  font-weight: 800;
  font-size: 25px;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.bot-cont {
  width: 973px;
  margin: 0 auto;
  .cont-tlt {
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 15px;
  }
  .seller {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .swipers {
      width: 100%;
      height: 658px;
      .swiper2.el-carousel {
        width: 100%;
        height: 466px !important;
        .el-carousel__container {
          height: 466px !important;
          position: relative !important;
          .el-carousel__item {
            height: 466px !important;
            background-size: 100% 100%;
            background-blend-mode: multiply;
            .el-carousel__item img {
              height: 466px !important;
              mix-blend-mode: multiply !important;
            }
          }
        }
      }
    }
    .swiper-bot {
      width: 100%;
      height: 192px;
      background: #aed1ec;
      border-radius: 0px 0px 0px 0px;
      padding: 20px 28px;
      .sw-tlt {
        width: 530px;
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 20px;
        color: #12416b;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .sw-tlt2 {
        width: 512px;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        font-size: 15px;
        color: #000000;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 16px;
      }
      .sw-tlt3 {
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        font-size: 10px;
        color: #707070;
        line-height: 12px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 18px;
        .dot {
          width: 6px !important;
          height: 6px;
          background: #707070;
          border-radius: 50%;
        }
      }
    }
    .se-right {
      width: 280px;
      height: 658px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .se-item {
        width: 100%;
        .se-pic {
          width: 280px;
          height: 156px;
        }
        .se-mask {
          width: 280px;
          padding-right: 20px;
          font-family: Montserrat, Montserrat;
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          line-height: 14px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-top: 14px;
        }
      }
    }
  }
}
.xem {
  width: 973px;
  margin: 0 auto;
  font-family: Montserrat, Montserrat;
  font-weight: 500;
  font-size: 10px;
  color: #575757;
  line-height: 12px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 50px;
  padding-left: 20px;
}
</style>
