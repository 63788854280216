<template>
  <div id="app">
    <nav>
      <div class="nav-list">
        <img
          class="logo"
          @click="goHome"
          src="./assets/image/logo2.png"
          alt=""
        />
        <div class="nav-item">
          <div class="navs">
            <div class="two-nav">
              <router-link to="/">{{ $t('home') }} </router-link>
            </div>
            <div class="two-nav" style="position: relative">
              <!-- v-on:mouseover="navliclick" v-on:mouseout="gyzxhoverClose" -->
              <div>
                <router-link to="/goods">{{ $t('goodsList') }} </router-link>
              </div>
              <ul
                v-on:mouseover="navliclick"
                v-on:mouseout="gyzxhoverClose"
                class="dropdown"
                v-show="isdropdown && category.length > 0"
              >
                <li
                  v-for="subItem in category"
                  :key="subItem.id"
                  class="dropdown-item"
                >
                  <div class="dropdown-label" @click="goCate(subItem)">
                    {{ subItem.label }}
                  </div>
                  <div
                    class="dropdown-name"
                    v-for="(item, index) in subItem.content"
                    :key="index"
                  >
                    {{ item.name }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="two-nav">
              <router-link to="/about">{{ $t('about') }} </router-link>
            </div>
          </div>
          <div class="nav-btn" @click="translate()">{{ language }}</div>
          <div class="goback" @click="goBack">{{ $t('back') }}</div>
        </div>
      </div>
    </nav>

    <router-view :key="$route.fullPath" />
    <div class="wexin">
      <div>{{ $t('phone') }}: {{ callmessageInfo.phone }}</div>
      <div>{{ $t('email') }}: {{ callmessageInfo.email }}</div>
    </div>
  </div>
</template>
<script>
import { frontproductList, categoryinfo, callmessage } from '@/api/index'
export default {
  name: 'HomeView',
  data() {
    return {
      language: '中文', // 选中的语言
      languagelist: ['cn', 'en'],
      category: [],
      isdropdown: false,
      callmessageInfo: {}
    }
  },
  components: {},
  computed: {},
  mounted() {},
  created() {
    if (localStorage.getItem('lang') == 'cn') {
      this.language = '中英'
    } else {
      this.language = 'Chinese/English'
    }
    this.callmessage()
  },
  methods: {
    goHome() {
      this.$router.push({
        path: '/'
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    callmessage() {
      callmessage({ lanage: localStorage.getItem('lang') }).then((res) => {
        if (res.code == 200) {
          // console.log('5555555', res)
          this.callmessageInfo = res.data[0]
        }
      })
    },
    goCate(item) {
      this.$router.push(
        {
          path: '/goods',
          query: { cateId: item.id }
        },
        () => {}
      )
      // this.$router.push({
      //   path: '/goods',
      //   query: { cateId: item.id }
      // })
    },
    navliclick() {
      console.log('2222222')
      this.isdropdown = true
    },
    gyzxhoverClose() {
      this.isdropdown = false
    },
    categoryinfo() {
      categoryinfo({
        lanage: localStorage.getItem('lang')
      }).then((res) => {
        if (res.code == 200) {
          this.category = res.data
          this.category.forEach((ele) => {
            frontproductList({
              lanage: localStorage.getItem('lang'),
              id: ele.id
            }).then((res2) => {
              if (res2.code == 200) {
                this.$set(ele, 'content', res2.data)
              }
            })
          })
          console.log('this.category666', this.category)
        }
      })
    },

    translate() {
      if (localStorage.getItem('lang') == 'cn') {
        localStorage.setItem('lang', 'en')
        this.language = 'Chinese/English'
      } else {
        localStorage.setItem('lang', 'cn')
        this.language = '中英'
      }
      this.$i18n.locale = localStorage.getItem('lang', 'cn')
      window.location.reload()
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  min-height: calc(100vh - 50px);
  position: relative;
}
* {
  box-sizing: border-box;
}
nav {
  width: 100%;
  height: 59px;
  background: #12416b;
  border-radius: 0px 0px 0px 0px;
  padding: 20px 194px;
  // position: relative;
  .nav-list {
    width: 100%;
    // height: 59px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10000;
    position: relative;
    .logo {
      width: 145px;
      height: 30px;
      cursor: pointer;
    }
    .nav-item {
      width: 700px;
      // height: 59px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .navs {
        // width: 100%;
        // height: 59px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
          font-family: Montserrat, Montserrat;
          font-weight: bold;
          font-size: 14px;
          color: #ffffff;
          line-height: 12px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          text-decoration: none;
          margin-right: 50px;
          .el-icon-arrow-down {
            color: #f7941d;
          }
          &.router-link-exact-active {
            font-family: Montserrat, Montserrat;
            font-weight: bold;
            font-size: 14px;
            color: #f7941d;
            line-height: 12px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }
        }
      }

      .nav-btn {
        width: 100px;
        height: 24px;
        background: #f7941d;
        border-radius: 91px 91px 91px 91px;
        text-align: center;
        line-height: 24px;
        font-family: Montserrat, Montserrat;
        font-weight: 600;
        font-size: 11px;
        color: #12416b;
        font-style: normal;
        text-transform: none;
        cursor: pointer;
      }
    }
  }
}
.two-nav {
  width: 150px;
  // height: 59px;
}
.dropdown {
  width: auto;
  position: absolute;
  top: 100%; /* 使下拉菜单出现在导航项下方 */
  left: 0;
  background-color: #fff;
  margin: 0;
  padding: 10px 10px;
  list-style: none;
  display: block;
  z-index: 1000; /* 确保下拉菜单在其他元素之上 */
}

.dropdown-item {
  padding: 10px;
}
.dropdown-label {
  font-family: Montserrat, Montserrat;
  font-weight: bold;
  font-size: 14px;
  color: #12416b;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
}
.dropdown-name {
  font-family: Montserrat, Montserrat;
  font-weight: bold;
  font-size: 14px;
  color: #6c6c6c;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
}
// .dropdown-item:hover {
//   background-color: #555; /* 鼠标悬停效果 */
// }
.wexin {
  position: absolute;
  bottom: 30px;
  right: 30px;
  div {
    font-size: 12px;
    color: #12416b;
  }
}
.goback {
  font-family: Montserrat, Montserrat;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  line-height: 12px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
}
</style>
